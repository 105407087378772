import { Container } from "react-bootstrap";

export default function License() {
    return (
        <>
            <Container>
                <h1>Licensing</h1>
                <p>Thank you for choosing INDIANFOOTAGE, an online platform for purchasing and downloading Indian stock footage. Our licensing agreements are designed to protect both the creators of the footage and the users of the footage. Please read this page carefully to understand the terms and conditions of using INDIANFOOTAGE.</p>

<p>Footage License: Upon purchasing any stock footage from INDIANFOOTAGE, you are granted a non-exclusive, non-transferable, worldwide license to use the footage in accordance with the terms of this license. The license allows you to use the footage for a wide range of commercial and personal projects, including films, television shows, commercials, presentations, and other multimedia projects. .</p>

<p>License Restrictions: You may not sell, redistribute, or sub-license the footage without the express written consent of INDIANFOOTAGE. Additionally, you may not use the footage in any illegal or unethical manner, including but not limited to, the promotion of hate speech, racism, or discrimination. .</p>

<p>Ownership: The ownership of the footage remains with the creator of the footage and INDIANFOOTAGE. You do not acquire any ownership rights to the footage by purchasing a license from INDIANFOOTAGE. .</p>

<p>Payment Terms: Payments for the stock footage must be made in Indian Rupee. You will be prompted to provide payment information at the time of purchase. Once payment has been processed, you will be able to download the footage immediately. .</p>

<p>Refund Policy: We do not offer any refunds for purchases made on INDIANFOOTAGE. All sales are final. .</p>

<p>Termination: INDIANFOOTAGE reserves the right to terminate this license at any time if you breach any of the terms and conditions of this agreement. Upon termination, you must immediately cease all use of the footage and destroy all copies of the footage in your possession. .</p>

<p>Governing Law: This license will be governed by and construed in accordance with the laws of India, without giving effect to any principles of conflicts of law. .</p>

<p>Disclaimer: INDIANFOOTAGE makes no representations or warranties as to the completeness, accuracy, or reliability of the footage. INDIANFOOTAGE will not be liable for any direct, indirect, incidental, or consequential damages arising from the use of the footage. .</p>

<p>By purchasing a license for any stock footage from INDIANFOOTAGE, you agree to be bound by the terms and conditions of this license agreement. If you have any questions or concerns, please contact us at info@indianfootage.com.</p>
            </Container>
        </>
    )
}