
  export default function Contact () {

    return (
      <>
    
       <h1>Contact Me</h1>;
        
      </>
    );
  }