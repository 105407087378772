import Search from './../components/search';
import { Container, Row, Col } from 'react-bootstrap';


export default function SearchContainer() {
    return (
        <>
            <Container>
                <div className='search-container w-100 h-100 d-flex align-items-center mt-5'>
                    <Search />
                </div>
            </Container>

        </>
    )
}