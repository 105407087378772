import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Preloader from '../components/preloader';


function MyOrder() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const cat = params.get("cat");
  const subcat = params.get("subcat");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [color, setColor] = useState([]);
  const [Ecolor, setEcolor] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {

    fetch("https://indianfootage.com/api/sync_tname_order.php?tname=footage_order_details&cust_id=" + localStorage.user_id)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.items);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
    fetch("https://indianfootage.com/api/sync_tname.php?tname=footage_product&cat=" + cat)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setColor(result.items);
        }
      );
    fetch("https://indianfootage.com/api/sync_tname_entire_shot.php?tname=footage_product&subcat=" + subcat)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setEcolor(result.items);
        }
      );
    fetch("https://indianfootage.com/api/sync_tname_specific.php?tname=footage_category&id=" + cat)
      .then(res => res.json())
      .then(
        (result) => {

          setCategory(result.items);
        }
      )
  }, [id])
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  else if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <>
      <Helmet>
        <title>
          My order - Indianfootages
        </title>
      </Helmet>
      <Container className="mt-3">
        <Row>
          <h1 className="font-24 mb-4 text-center text-md-start">
            My order list<br />
           
          </h1>
          <h6> {localStorage.name}</h6>
          {items.map((myOrder, i) => (
            <Col className="col-md-6">
              <Row>
                <Col>
                  <img src="" alt="" />
                  <video key={myOrder.order_id} width="320" height="240" controls className='p-2 img-fluid w-100' loop muted autoplay>

                    <source src={myOrder.thumbnail_video} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </Col>
                <Col>
                  <p>Clip ID: {myOrder.prod_id}</p>
                  <p>Order ID: {myOrder.order_id}</p>
                  <p>File type: MP4</p>
                  <div className="d-flex justify-content-center justify-content-md-start my-3">
                    <a href={myOrder.original_video} target="_blank" rel='noreferrer' className="btn-outline-dark d-flex align-items-center border px-3 py-1">
                      <span className="material-symbols-outlined me-2">
                        cloud_download
                      </span>
                      <span>Download</span>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default MyOrder;