import { Container, Row, Col, Button, Card} from 'react-bootstrap';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DeleteFromCart from "../components/deleteButton";
export default function Cart() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [countre, setCountre] = useState(0);
  useEffect(() => {


    setIsLoaded(true);
    var cart = [];
    cart = JSON.parse(localStorage.cart);
    setItems(cart);
  }, [countre])
  setInterval(function () {var cart = [];
    if (localStorage.cart) {

        cart = JSON.parse(localStorage.cart);

    }
    var len = cart.length;
   // alert(len);
   setCountre((countre) => len);
}, 1000);
  var cart = [];
if (localStorage.cart) {

    cart = JSON.parse(localStorage.cart);

}
var len=cart.length;
var total_price=0;
for(let i=0;i<len;i++)
{
  //alert(cart[i].Price);
  total_price=total_price+parseFloat(cart[i].Price);
}
function checkout()
{
  if(localStorage.user_id)
  {
    window.location.replace('/#/Checkout');
  }
  else{
    alert("You have to login first");
  }
}
//alert(price);
  return (

    <>

      <Container className="mt-5 mb-5">
        <Col className="mb-5">
          <h1 className='font-32  pb-2'>Shopping Cart</h1>
        </Col>
        <Row>
          <Col md={8}>
            {items.map((cart, i) => (
              <div className='d-flex flex-wrap mb-3'>
                <div className='col-lg-4'><img src={cart.Image} alt="" className='img-fluid' /></div>
                <div className='col-lg-8 ps-4'>
                  <p className="mb-1"><span className="bold">Clip ID:</span> {cart.Name}</p>
                  <p className="mb-1"><span className="bold">Qty:</span>{cart.Qty}</p>
                  <p className="mb-1 d-flex align-items-center"><span className="bold">Price: </span> <span className="material-symbols-outlined font-16">
                    currency_rupee
                  </span> {cart.Price} &nbsp;&nbsp;  <DeleteFromCart id={cart.product_id} btntype={"cart"}/></p>
                </div>

              </div>

            ))}
          </Col>
          <Col md={4}>
            <Card className="mb-2 bg-dark text-white"
            >
              <Card.Header><h2 className='font-20 mb-0'>Checkout Summary</h2></Card.Header>
              <Card.Body>
                <Card.Title  className='font-16 mb-3'>Thank you for shopping with us</Card.Title>
                <Card.Text>
                  <p className="mb-1"><span className="bold">Items:</span> {len}</p>
                  <p className="mb-3 d-flex align-items-center"><span className="bold">Total:</span> <span className="material-symbols-outlined font-16">
                    currency_rupee
                  </span> {total_price} (inc. of taxes)</p>
                  <div className='d-grid px-5 mt-5'>
                    <Button variant="secondary" type="submit" className='btn py-2 mb-3 btn-checkout' onClick={() => checkout()}>Checkout</Button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>


    </>
  )
}