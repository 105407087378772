import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
export default function CustFooter() {
    let location = useLocation();
    //  alert(location.pathname);
    if(location.pathname == "/Login")
    {
    }
    else if(location.pathname == "/signup")
    {
 
    }
    else if(location.pathname == "/Joinaspremium")
    {
     
    }
    else
    {
    return (
        <>
            <footer className="pt-5 pb-4">
                <Container >
                    <Row>
                        <Col lg={6} className='border-top pt-4 pt-lg-0 order-4 order-lg-1 text-center text-md-start'>
                            <h3 className="font-16 mb-lg-4">Connect with us: 1800-11-6869</h3>
                            <ListGroup horizontal as="ul" className='mb-lg-5 d-flex flex-wrap justify-content-center justify-content-md-start'>
                                <ListGroup.Item as="li" className='p-0 border-0'><img src='./images/visa.png' alt="Visa" className="img-fluid img-small" /></ListGroup.Item>
                                <ListGroup.Item as="li" className='p-0 border-0'><img src='./images/mastercard.png' alt="Master" className="img-fluid img-small" /></ListGroup.Item>
                                <ListGroup.Item as="li" className='p-0 border-0'><img src='./images/googlepay.png' alt="gpay" className="img-fluid img-small" /></ListGroup.Item>
                            </ListGroup>
                            <p className='mb-0'>&copy; Indianfootages. All rights reserved.</p>
                        </Col>

                        <Col md={4} lg={2} className='mb-3 order-1 order-lg-2'>
                            <h3 className='font-20 normal mb-md-4'>Company Info</h3>

                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='px-0 border-0'>
                                    <Link to="/">Home</Link>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className='px-0 border-0'>
                                    <Link to="/about">About</Link>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className='px-0 border-0'>
                                    <Link to="/License">Licensing</Link>
                                </ListGroup.Item>

                            </ListGroup>

                        </Col>

                        <Col md={4} lg={2} className='px-md-0 mb-3 mb-lg-0 order-2 order-lg-3'>
                            <h3 className='font-20 normal mb-md-4'>Learn more</h3>

                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='px-0 border-0'>
                                    <Link to="/pricing">Join as Premium</Link>
                                </ListGroup.Item>
                               
                                <ListGroup.Item as="li" className='px-0 border-0'>
                                    <Link to="/Terms">Terms of Use</Link>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className='px-0 border-0'>
                                    <Link to="/privacy">Privacy Policy</Link>
                                </ListGroup.Item>
                            </ListGroup>

                        </Col>

                        <Col md={4} lg={2} className='px-md-0 order-3 order-lg-4'>
                            <h3 className='font-20 normal mb-md-4'>Need Help</h3>

                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='px-0 border-0'>
                                    <Link to="/contact">Contact Us</Link>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className='px-0 border-0'>
                                    <Link to="/faq">FAQ</Link>
                                </ListGroup.Item>
                              
                            </ListGroup>

                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
     }
}