import { Container, Navbar } from 'react-bootstrap';
import logo from '../../logo_1.svg';
import CustNavOffcanvas from './navbar';
import CartNav from './cart_nav';
import { useLocation } from 'react-router-dom';

export default function Header() {
    let location = useLocation();
    //  alert(location.pathname);
    if (location.pathname == "/Login") {
    }
    else if (location.pathname == "/signup") {

    }
    else if (location.pathname == "/Joinaspremium") {

    }
    else {
        return (
            <>
                <header>
                    <Navbar collapseOnSelect expand="lg" variant="light" sticky="top">
                        <Container fluid className='position-relative'>
                            <CustNavOffcanvas />
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Brand className='m-0 ms-md-5'>
                                <a href="/">
                                    <img src={logo} alt='Indian footages Logo' className='img-fluid' />
                                </a>
                            </Navbar.Brand>
                            <CartNav />
                        </Container>
                    </Navbar>
                </header>
            </>
        );
    }
}