import { Container, ListGroup } from "react-bootstrap";
import MasonryFile from './../components/masonry';
import TagCloud from '../components/keyword';
import MantleFile from '../components/mantle_home';
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
        <>
            <Helmet>
                <title>Welcome to Indianfootage</title>
            </Helmet>

            <main>
                <MantleFile />
                <MasonryFile />
                <Container fluid className="text-center py-5 popular-search">
                    <h2 className='font-32 semi-bold mb-5'>Popular Searches</h2>
                    <TagCloud />
                </Container>
            </main>
        </>
    )
}