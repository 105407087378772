import { Container } from "react-bootstrap";

export default function Privacy() {
    return (
        <>
            <Container>
                <h1>Privacy Policy</h1>
                

<p>Introduction: This privacy policy (the "Policy") applies to INDIANFOOTAGE, an online platform for purchasing and downloading Indian stock footage. INDIANFOOTAGE takes the privacy of its users seriously and is committed to protecting your personal information. This Policy explains what personal information we collect and how we use it.</p>

<p>Information Collection: We may collect the following types of information when you use INDIANFOOTAGE: (i) contact information, such as your name, email address and phone number; (ii) payment information, such as your credit card number and billing information; (iii) information about your use of INDIANFOOTAGE, such as the Stock Footage you purchase and download.</p>

<p>Use of Information: We use the information we collect for the following purposes: (i) to provide the INDIANFOOTAGE services; (ii) to process payment for the Stock Footage you purchase; (iii) to improve the INDIANFOOTAGE website; (iv) to communicate with you about your account and INDIANFOOTAGE services.</p>

<p>Sharing of Information: We will not sell, rent or share your personal information with any third party except as described in this Policy or as required by law. We may share your information with our service providers who assist us in providing INDIANFOOTAGE services, such as payment processing and website hosting. These service providers are bound by contract to use your information only for the purposes of providing the services we request and not for their own benefit.</p>

<p>Data Security: We take reasonable measures to protect your personal information from unauthorized access, use or disclosure. However, no internet-based platform can be 100% secure and we cannot guarantee the security of your information.</p>

<p>Retention of Information: We will retain your information for as long as your account is active or as necessary to provide you with INDIANFOOTAGE services.</p>

<p>Access to Information: You have the right to access and correct your personal information. You may do so by logging into your INDIANFOOTAGE account and updating your information.</p>

<p>Changes to Policy: We may change this Policy from time to time. We will post any changes on the INDIANFOOTAGE website, and your continued use of INDIANFOOTAGE following any changes constitutes your acceptance of the new Policy.</p>

<p>Governing Law: This Policy will be governed by and construed in accordance with the laws of India, without giving effect to any principles of conflicts of law.</p>

<p>Contact Information: If you have any questions or concerns about this Policy, please contact us at info@indianfootage.com</p>

            </Container>
        </>
    )
}