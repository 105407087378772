import ProgressBar from 'react-bootstrap/ProgressBar';
import { Container } from 'react-bootstrap';

export default function AnimatedExample() {
    return (
        <>
            {/* <Container className='my-5'>
                <ProgressBar animated now={100} label='Loading...' className='m-2' />
            </Container> */}
        </>
    )
}
