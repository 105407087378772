import { Container } from "react-bootstrap";

export default function Terms() {
    return (
        <>
            <Container>
                <h1>Terms Of Use</h1>
                <p>Introduction: These terms of use (the "Terms") apply to your use of INDIANFOOTAGE, an online platform for purchasing and downloading Indian stock footage (the "Stock Footage"). By accessing or using INDIANFOOTAGE, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use INDIANFOOTAGE.</p>

<p>License Grant: Upon payment of the applicable fee, INDIANFOOTAGE grants you a non-exclusive, non-transferable, perpetual license to use the Stock Footage for personal or commercial purposes in accordance with these Terms.</p>

<p>Restrictions on Use: You may not redistribute, sublicense, rent, sell or otherwise make the Stock Footage available to any third party, or use the Stock Footage in any manner that competes with INDIANFOOTAGE's business. You may not use the Stock Footage in a way that is obscene, defamatory, infringing or otherwise illegal.</p>

<p>Intellectual Property: The Stock Footage is the property of INDIANFOOTAGE or its licensors, and is protected by Indian and international copyright and trademark laws. You acknowledge and agree that the Stock Footage is provided to you for your use only and that you do not acquire any ownership rights by using the Stock Footage.</p>

<p>Payments: All payments for Stock Footage on INDIANFOOTAGE must be made in Indian Rupee. INDIANFOOTAGE reserves the right to change its fees and payment policies at any time, and will provide notice of any such changes on the website.</p>

<p>Representations and Warranties: INDIANFOOTAGE represents and warrants that it has the right to grant the license set forth in these Terms and that the Stock Footage does not infringe the rights of any third party.</p>

<p>Disclaimer of Warranties: The Stock Footage is provided "as is" and INDIANFOOTAGE makes no warranties, express or implied, as to the quality, accuracy or reliability of the Stock Footage.</p>

<p>Limitation of Liability: INDIANFOOTAGE will not be liable for any damages arising out of or in connection with your use of the Stock Footage, whether based in contract, tort, or any other legal theory, and regardless of whether INDIANFOOTAGE has been advised of the possibility of such damages.</p>

<p>Governing Law: These Terms will be governed by and construed in accordance with the laws of India, without giving effect to any principles of conflicts of law.</p>

<p>Changes to Terms: INDIANFOOTAGE may change these Terms at any time by posting revised Terms on the website. Your continued use of INDIANFOOTAGE following any such changes constitutes your acceptance of the new Terms.</p>

<p>General: These Terms constitute the entire agreement between you and INDIANFOOTAGE regarding the use of the Stock Footage, and supersede all prior agreements and understandings between you and INDIANFOOTAGE. If any provision of these Terms is found to be unenforceable, the remaining provisions will remain in full force and effect. The failure of INDIANFOOTAGE to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing.</p>


            </Container>
        </>
    )
}