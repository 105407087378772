import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useState } from "react";
import logo from '../../src/logo_1.svg';

export default function Joinaspremium() {
 
    let handleConfirmOrder = async (e) => {
        e.preventDefault();
      //alert(localStorage.cart);
        try {
            let res = await fetch("https://indianfootage.com/api/join_as_premium_request.php?user_id="+localStorage.user_id, {
                method: "POST",
                body: localStorage.cart,
            });
            let resJson = await res.json();
            if (res.status === 200) {
                
               //alert(JSON.stringify(resJson));
              //  setMessage(resJson.Message);
              //alert(resJson.order_string);
                if(resJson.order_status=="1")
                {
                  
                   alert("Please pay for order id: "+resJson.order_id);
                   localStorage.cart="";
                   window.location.replace(resJson.payment_url);
                   //window.location.replace('/');
                   // window.location.replace('/');
                }
                else{
                    
      
                }
            } else {
               // setMessage("Some error occured");
            }
        } catch (err) {
            console.log(err);
        }
      };
      if (localStorage.dmz=="ddd") {
    return (
        <>
            <Container fluid className='d-flex justify-content-center p-0'>
                <div className='d-flex w-100 h-100 align-items-center'>
                    <div className='col-md-8 col-xxl-7 d-none d-md-block p-0' style={{
                        backgroundImage: 'url(https://images.pexels.com/photos/13617132/pexels-photo-13617132.jpeg)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: window.innerHeight
                    }}>

                    </div>

                    <main className='col-12 col-md-4 px-md-5 col-xxl-5'>
                        <a href="/">
                            <img src={logo} alt='Indian footages Logo' className='img-fluid mb-3' />
                        </a>
                        <h1 className='font-32'>Join With Us</h1>
                        <p className='d-flex align-items-center'>Annual Subscription <span class="material-symbols-outlined">
                            currency_rupee
                        </span> 7000</p>
                        <p>Please join us with annual subscription. This subscription will allow you for unlimited downloads for lifetime. We will  add video throughout the year so you will not miss any footage. Also it is very much pocket friendly for you.</p>

                        <Form>
                        <label>Select Payment Details</label>
                            {['radio'].map((type) => (
                                <div key={`default-${type}`} className="mb-3">
                                     <input type="radio" value="Male" name="gender" checked /> Pay Online
                                </div>
                            ))}
                        </Form>

                        <Row className='d-flex justify-content-center mt-3'>
                            <Col className='col-auto me-md-4'>
                                <Button variant="primary" type="submit" className='btn btn-secondary py-2 px-4' onClick={handleConfirmOrder}>
                                    Subscribe
                                </Button>
                            </Col>
                        </Row>

                    </main>
                </div>
            </Container>
        </>
    )
                            }
                            else if (localStorage.dmz=="sss") {


                                return (
                                    <>
                                        <Container fluid className='d-flex justify-content-center p-0'>
                                            <div className='d-flex w-100 h-100 align-items-center'>
                                                <div className='col-md-8 col-xxl-7 d-none d-md-block p-0' style={{
                                                    backgroundImage: 'url(https://images.pexels.com/photos/13617132/pexels-photo-13617132.jpeg)',
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center',
                                                    height: window.innerHeight
                                                }}>
                            
                                                </div>
                            
                                                <main className='col-12 col-md-4 px-md-5 col-xxl-5'>
                                                    <a href="/">
                                                        <img src={logo} alt='Indian footages Logo' className='img-fluid mb-3' />
                                                    </a>
                                                    <h1 className='font-32'>You are already a premium member</h1>
                                                    <p className='d-flex align-items-center'>Now Enjoy Annual Subscription <span class="material-symbols-outlined">
                                                        currency_rupee
                                                    </span> 7000</p>
                                                    <p>With annual subscription. You are allowed for for unlimited downloads for lifetime. We will  add video throughout the year so you will not miss any footage. Also it is very much pocket friendly for you.</p>
                            
                                                   
                            
                                                </main>
                                            </div>
                                        </Container>
                                    </>
                                )

                            }
}