import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Preloader from '../components/preloader';
import SearchContainer from '../components/search_container';
import AddToCart from "../components/addToCartButton";

export default function Plp() {

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cat = params.get("cat");


  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState([]);
  console.log("https://indianfootage.com/api/sync_tname.php?tname=footage_product&cat=" + cat);
  useEffect(() => {
    setIsLoaded(false);
    fetch("https://indianfootage.com/api/sync_tname.php?tname=footage_product&cat=" + cat)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.items);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
    fetch("https://indianfootage.com/api/sync_tname_specific.php?tname=footage_category&id=" + cat)
      .then(res => res.json())
      .then(
        (result) => {

          setCategory(result.items);
        }
      )


  }, [cat])
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  else if (!isLoaded) {
    return <Preloader />;
  }
  else {
    return (
      <>
        <Helmet>
          <title>PLP Category - Indianfootage</title>
        </Helmet>
        <SearchContainer />

        <Container className="mt-4">

          <Row className="px-3 px-md-0">
            <Breadcrumb className="px-0 mb-3 d-flex justify-content-center justify-content-md-start">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              {category.map((cat, i) => (
                <Breadcrumb.Item active>{cat.category}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
            {category.map((cat, i) => (
              <h1 className='font-32 mb-3 px-0'>{cat.category}</h1>
            ))}
          </Row>

          <Row className="mb-3">
            {items.map((plp, i) => (
              <Col className='col-12 col-md-4 ps-0 pe-1 position-relative plp_img'>

                <ul className="d-inline-flex position-absolute wishlist border-0">
                  <li className="p-0 me-2 d-flex justify-content-center border-0">
                    <Button className="d-flex align-items-center justify-content-center border-0" title="Add to wishlist">
                      <span class="material-symbols-outlined font-16">
                        favorite
                      </span>
                    </Button>
                  </li>

                  <li className="p-0 d-flex justify-content-center border-0">
                    <AddToCart id={plp.id} qty={1} name={plp.name} image={plp.thumbnail_image} price={plp.price} btntype={"plp"} />


                  </li>
                </ul>

                {/* <span className="position-absolute mb-0 text-center w-100 title">{plp.name}</span> */}
                <a href={"/#/pdp?id=" + plp.id + "&cat=" + plp.category+"&subcat=" + plp.subcategory} className="position-relative">
                  <span className="d-flex align-items-center title position-absolute">
                    <span class="material-symbols-outlined">
                      play_arrow
                    </span>
                    {plp.resolution} {plp.duration}
                  </span>

                  <video id={plp.id} className='px-0 mb-2 mb-md-0 img-fluid w-100' loop muted preload="none" poster={plp.thumbnail_image}
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.load()}>
                    <source src={plp.thumbnail_video} type='video/mp4' />
                  </video>
                </a>

              </Col>
            ))}

          </Row>
        </Container>
      </>
    )
  }
}
