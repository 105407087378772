import { Container } from "react-bootstrap";

export default function About() {
    return (
        <>
            <Container>
                <h1>About us</h1>
                <p>Welcome to INDIANFOOTAGE, a premier online platform for purchasing and downloading Indian stock footage. Our philosophy is to democratize access to Indian stock footages and make it affordable and accessible to everyone. We believe that everyone should have access to high-quality footages from across India to fuel their creative projects and businesses.</p>

<p>With over 20 years of experience in the industry, we have a deep understanding of the needs of our customers and are committed to providing them with the best stock footage that India has to offer. Our vision is to provide everyone with access to a wide range of high-quality footages, regardless of their experience or budget.</p>

<p>At INDIANFOOTAGE, we understand the importance of affordability and accessibility. That's why we have kept our subscription fee very nominal to support infrastructure costs so that we can continue to serve new-age businesses like us. Our website is geared towards content creators, budding entrepreneurs, marketing professionals, agencies, and business houses, providing them with the resources they need to create stunning visual content.</p>

<p>We are proud to be part of the "Make in India" movement and believe that our platform can play a significant role in supporting and promoting Indian businesses. Our endeavor is to fuel the creativity and growth of Indian businesses by providing them with the resources they need to succeed.</p>

<p>At INDIANFOOTAGE, we are committed to providing the highest quality stock footage and customer service. Whether you are a content creator, entrepreneur, marketing professional, or business house, we are here to help you achieve your creative vision. Contact us today to learn more about how we can help you bring your ideas to life.</p>

            </Container>
        </>
    )
}