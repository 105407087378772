import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { useState } from "react";
export default function Search() {
    const [name, setName] = useState("");
    return (
        <Container>
            <Row>
                <Col md={{ span: 6, offset: 3 }} className='position-relative text-center '>
                    <Form action={"/#/search?search=" + name}>
                        <Form.Group controlId="search">
                            <Row className='d-flex align-items-center flex-wrap search-outline'>
                                <div className="dropdown position-absolute">
                                    <button className="btn dropdown-toggle d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="material-symbols-outlined me-1">
                                            videocam
                                        </span> Video
                                    </button>
                                    <ul className="dropdown-menu">
                                        {/* <li><button className="dropdown-item btn" disabled>Images comng soon</button></li> */}
                                        <li><button className="dropdown-item btn">Video</button></li>
                                    </ul>
                                </div>
                                <Form.Control value={name} onChange={(e) => setName(e.target.value)} autocomplete="false" autoFocus type="text" placeholder="Search here" className='search-input border-0 px-0' />
                                {/* <datalist id="browsers">
                                    <option value="abc"></option>
                                </datalist> */}
                                <Button className='btn-clear position-absolute border-0 d-flex align-items-center px-2'>
                                    <span className="material-symbols-outlined">
                                        close
                                    </span>
                                </Button>

                                <Button className='btn-search position-absolute border-0 d-flex align-items-center px-2'>
                                    <span className="material-symbols-outlined">
                                        search
                                    </span>
                                </Button>
                            </Row>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}