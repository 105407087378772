import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useState } from "react";
import logo from '../../src/logo_1.svg';

export default function Forget() {
    //const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    //const [mobileNumber, setMobileNumber] = useState("");
    //const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let res = await fetch("https://indianfootage.com/api/forget_password.php", {
                method: "POST",
                body: JSON.stringify({
                    email: email
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {

                 //alert(JSON.stringify(resJson));
                 alert(resJson.Message);
                setMessage(resJson.Message);
                if (resJson.login_status === "1") {
                   
                    window.location.replace('/#/Login');
                }
                else {
                   
                    alert(resJson.Message);
                }
            } else {
                setMessage("Some error occured");
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <Container fluid className='d-flex justify-content-center p-0'>
                <div className='d-flex w-100 h-100 align-items-center'>
                    <div className='col-md-8 col-xxl-7 d-none d-md-block p-0' style={{
                        backgroundImage: 'url(https://images.pexels.com/photos/3733302/pexels-photo-3733302.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: window.innerHeight
                    }}>

                    </div>

                    <main className='col-12 col-md-4 px-md-5 col-xxl-5'>
                    <a href="/">
                            <img src={logo} alt='Indian footages Logo' className='img-fluid mb-3' />
                        </a>   
                        <h1 className='font-32'>Log In</h1>
                        <p className='text-muted'>We'll never share your email with anyone else.</p>

                        <Row className='mt-2 w-100'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label visuallyHidden>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>

                                
                                <div className="my-2 message">{message ? <p>{message}</p> : null}</div>
                                <div className="d-grid mt-5">
                                    <Button variant="primary" type="submit" className='btn btn-secondary py-2'>
                                    Forgot Password
                                    </Button>
                                </div>
                               
                            </Form>
                        </Row>
                        <Row className='d-flex justify-content-end mt-3'>
                            <Col className='col-auto me-md-4'>
                                <a variant="outline-dark" className="d-flex align-items-center border-0" href="/#/signup">
                                    Create New Account
                                </a>
                            </Col>
                        </Row>

                    </main>
                </div>
            </Container>
        </>
    )
}