import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap';
var cart = [];
if (localStorage.cart) {

    cart = JSON.parse(localStorage.cart);

}
function addToCart(product_id, qty, name, image, price) {
    //  alert();
    for (var i in cart) {
        if (cart[i].product_id == product_id) {
            console.log("Already In Cart");
            // cart[i].Qty = parseInt(cart[i].Qty) + parseInt(qty);  // replace existing Qty
            //showCart();
            saveCart();
            //alert("l");
            showCart();
            return;
        }
    }
    var item = { Header: "I", product_id: product_id, Qty: qty, Name: name, Image: image, Price: price };
    cart.push(item);
    saveCart();

}
function saveCart() {
    localStorage.cart = JSON.stringify(cart);
}
function deleteItem(product_id) {
    cart = JSON.parse(localStorage.cart);
//alert(product_id);
    for (var i in cart) {

        if (cart[i].product_id == product_id) {
            var index = i;  // replace existing Qty
          //  alert(index);

        }
    }
//alert(JSON.stringify(cart));
    cart.splice(index, 1);
   // alert(JSON.stringify(cart));
   localStorage.cart = JSON.stringify(cart);

    //showCart();

}
function showCart() {
    cart = JSON.parse(localStorage.cart);

    var outp = JSON.stringify(cart);

}
function IncreaseItem(product_id) {

    for (var i in cart) {

        if (cart[i].product_id == product_id) {
            cart[i].Qty = parseInt(cart[i].Qty) + 1;

        }
    }


    saveCart();

    showCart();

}
function DecreaseItem(product_id) {

    for (var i in cart) {

        if (cart[i].product_id == product_id) {
            if (cart[i].Qty == 1) {
                deleteItem(product_id);

                return;
            }
            cart[i].Qty = parseInt(cart[i].Qty) - 1;

        }
    }


    saveCart();

    showCart();

}
function DeleteFromCart(props) {

    return (
        <div>
            {(() => {
                if (props.btntype == "cart") {
                    return (
                        <Button variant='light' className='border-0 d-flex align-items-center' onClick={() => deleteItem(props.id)} title="Remove item">
                            <span className="material-symbols-outlined ">
                                delete
                            </span>
                        </Button>

                    )

                }

            })()}

        </div>
    );
}

export default DeleteFromCart