import { ListGroup } from "react-bootstrap";
import React, { useEffect,useState } from "react";
import Preloader from '../components/preloader';
export default function TagCloud() { 
    const [color, setColor] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
    fetch("https://indianfootage.com/api/sync_tname_tag.php?tname=footage_tags")
      .then(res => res.json())
      .then(
       
        (result) => {
          setIsLoaded(true);
          setColor(result.items);
          //alert();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    },[])
      if (error) {
        return <div>Error: {error.message}</div>;
      }
      else if (!isLoaded) {
        return <Preloader />;
      }
      else {
    return (
        <>
        
            <ListGroup as="ul" className="px-md-5 list-unstyled list-horizontal justify-content-center align-items-center border-0 flex-wrap" horizontal>
                {color.map((tag, i) => (
                    <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-4'>
                        <a href={"/#/search?search=" + tag.tags} className='btn-capsuel px-4 py-2 border-0' key={i}>{tag.tags}</a>
                    </ListGroup.Item>
                ))}                
            </ListGroup>
        
        </>
    )
}
}

