import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Container } from "react-bootstrap";

import Preloader from '../components/preloader';

class CustMasonry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        fetch("https://indianfootage.com/api/sync_customer.php?tname=footage_category")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.items
                    });
                   
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {
        
        const { error, isLoaded, items } = this.state;
        console.log(items[0]);
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Preloader/>;
        } else {
            return (
                <>
           
                    <Container className='masonry-text-container'>
                        <h1 className='font-32 text-center py-3 mb-0'>Welcome to Indian Footages</h1>
                        <p className='text-center'>We are the unmatched pioneers in providing the most picturesque and affordable 4K Indian stock video footage to content creators from India and abroad without compromising on quality.</p>
                    </Container>

                    <Container fluid className='masonry p-0 pm-3'>
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                            <Masonry>
                                {items.map((videoFiles, i) => (
                                    <video id={videoFiles.id} className='px-0 p-md-2 mb-2 mb-md-0 img-fluid w-100' loop muted preload="none" poster={videoFiles.poster}
                                        onMouseOver={event => event.target.play()}
                                        onMouseOut={event => event.target.load()}>
                                        <source src={videoFiles.path} type='video/mp4' />
                                    </video>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </Container>
                </>
            )
        }
    }
}

export default CustMasonry;