import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Button, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import SearchContainer from '../components/search_container';
import Preloader from '../components/preloader';
import AddToCart from "../components/addToCartButton";
export default function PDP() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const cat = params.get("cat");
  const subcat = params.get("subcat");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [color, setColor] = useState([]);
  const [Ecolor, setEcolor] = useState([]);
  const [category, setCategory] = useState([]);
  const [Tag, setTag] = useState([]);
  console.log("https://indianfootage.com/api/sync_product_detail.php?tname=footage_product&id=" + id);
  useEffect(() => {

    fetch("https://indianfootage.com/api/sync_product_detail.php?tname=footage_product&id=" + id)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.items);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
    fetch("https://indianfootage.com/api/sync_tname.php?tname=footage_product&cat=" + cat)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setColor(result.items);
        }
      );
    fetch("https://indianfootage.com/api/sync_tname_entire_shot.php?tname=footage_product&subcat=" + subcat)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setEcolor(result.items);
        }
      );
    fetch("https://indianfootage.com/api/sync_tname_specific.php?tname=footage_category&id=" + cat)
      .then(res => res.json())
      .then(
        (result) => {

          setCategory(result.items);
        }
      );
    fetch("https://indianfootage.com/api/sync_tname_tag.php?tname=footage_tags")
      .then(res => res.json())
      .then(

        (result) => {
          setIsLoaded(true);
          setTag(result.items);
        }
      )
  }, [id])
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  else if (!isLoaded) {
    return <Preloader />;
  }
  else {

    return (
      <>
        <Helmet>
          <title>
            {'pdp.name'} - Indianfootages
          </title>
        </Helmet>
        <SearchContainer />

        <Container className="mt-3">

          {items.map((pdp, i) => (
            <Row>
              <Breadcrumb className="d-flex justify-content-center justify-content-md-start">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                {category.map((cat, i) => (
                  <Breadcrumb.Item href={"/#/plp?cat=" + cat}>{cat.category}</Breadcrumb.Item>
                ))}
                <Breadcrumb.Item active>{pdp.name}</Breadcrumb.Item>
              </Breadcrumb>
              <Col md={6} className='p-md-0'>

                <video key={pdp.id} width="320" height="240" controls className='p-2 img-fluid w-100' loop muted autoplay>

                  <source src={pdp.thumbnail_video} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>


                <span className="d-flex justify-content-center justify-content-md-end mb-2">
                  <a href={pdp.thumbnail_video} target="_blank" rel='noreferrer' className="btn-outline-dark d-flex align-items-center border px-3 py-1">
                    <span className="material-symbols-outlined me-2">
                      cloud_download
                    </span>
                    <span>Download</span>
                  </a>
                </span>
              </Col>
              <Col md={6}>
                <h1 className="font-24 mb-4 text-center text-md-start">{pdp.name}</h1>
                <p>{pdp.description}</p>
                <Row>
                  <Col>
                    <p className="mb-1"><span className="bold">Clip ID:</span> {pdp.id}</p>
                    <p className="mb-1"><span className="bold">Format:</span> {pdp.format}</p>
                    <p className="mb-1"><span className="bold">Aspect Ratio:</span> {pdp.aspect_ration}</p>
                    <p className="mb-1"><span className="bold">Model Release:</span> {pdp.model_release}</p>
                  </Col>

                  <Col>
                    <p className="mb-1"><span className="bold">FPS:</span> {pdp.fps}</p>
                    <p className="mb-1"><span className="bold">Type:</span> {pdp.type}</p>
                    <p className="mb-1"><span className="bold">Duration:</span> {pdp.duration}</p>
                    <p className="mb-1"><span className="bold">Resolution:</span> {pdp.resolution}</p>
                  </Col>
                </Row>
                <Container className="border-top py-3 mt-3">
                  <Row className="align-items-center">
                    <Col className="d-flex align-items-center p-0">
                      <span className="material-symbols-outlined font-18">
                        currency_rupee
                      </span> <span className='font-24'>{pdp.price}.<small>00</small></span>
                    </Col>

                    <Col className='col-auto p-0'>
                      <AddToCart id={pdp.id} qty={1} name={pdp.name} image={pdp.thumbnail_image} price={pdp.price} btntype={"pdp"} />
                    </Col>
                    
                  </Row>
                  <Row>
                  <Col className='col-auto p-0 mt-4'>
                      <ListGroup as="ul" className="list-unstyled list-horizontal align-items-center border-0 flex-wrap" horizontal>
                        {Tag.map((tag, i) => (
                          <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-4'>
                            <a href={"/#/search?search=" + tag.tags} className='btn-capsuel px-4 py-2 border-0' key={i}>{tag.tags}</a>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          ))}
        </Container>

        <Container className="mt-3">

          <Col className="w-100 d-block">
            <h2 className='font-24 py-3 mb-0 px-0 text-center text-md-start'>Related Videos</h2>
          </Col>

          <Row>
            {color.map((plp, i) => (
              <Col className='col-12 col-md-4 ps-0 pe-1 position-relative plp_img'>

                <ul className="d-inline-flex position-absolute wishlist border-0">
                  <li className="p-0 me-2 d-flex justify-content-center border-0">
                    <Button className="d-flex align-items-center justify-content-center border-0" title="Add to wishlist">
                      <span class="material-symbols-outlined font-16">
                        favorite
                      </span>
                    </Button>
                  </li>

                  <li className="p-0 d-flex justify-content-center border-0">
                    <Button className="d-flex align-items-center justify-content-center border-0" title="Add to cart">
                      <span class="material-symbols-outlined font-16">
                        shopping_cart
                      </span>
                    </Button>
                  </li>
                </ul>

                {/* <span className="position-absolute mb-0 text-center w-100 title">{plp.name}</span> */}
                <a href={"/#/pdp?id=" + plp.id + "&cat=" + cat + "&subcat=" + subcat} className="position-relative">
                  <span className="d-flex align-items-center title position-absolute">
                    <span class="material-symbols-outlined">
                      play_arrow
                    </span>
                    {plp.resolution} {plp.duration}
                  </span>
                  <video id={plp.id} className='px-0 mb-2 mb-md-0 img-fluid w-100' loop muted preload="none" poster={plp.thumbnail_image}
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.load()}>
                    <source src={plp.thumbnail_video} type='video/mp4' />
                  </video>
                </a>
              </Col>
            ))}
          </Row>

          <Col className="w-100 d-block">
            <h2 className='font-24 py-3 mb-0 px-0 text-center text-md-start'>Entire Shoot</h2>
          </Col>


          <Row>
            {Ecolor.map((plp, i) => (
              <Col className='col-4 ps-0 pe-1 position-relative plp_img'>

                <ul className="d-inline-flex position-absolute wishlist border-0">
                  <li className="p-0 me-2 d-flex justify-content-center border-0">
                    <Button className="d-flex align-items-center justify-content-center border-0" title="Add to wishlist">
                      <span class="material-symbols-outlined font-16">
                        favorite
                      </span>
                    </Button>
                  </li>

                  <li className="p-0 d-flex justify-content-center border-0">
                    <Button className="d-flex align-items-center justify-content-center border-0" title="Add to cart">
                      <span class="material-symbols-outlined font-16">
                        shopping_cart
                      </span>
                    </Button>
                  </li>
                </ul>

                {/* <span className="position-absolute mb-0 text-center w-100 title">{plp.name}</span> */}
                <a href={"/#/pdp?id=" + plp.id + "&cat=" + cat + "&subcat=" + subcat} className="position-relative">
                  <span className="d-flex align-items-center title position-absolute">
                    <span class="material-symbols-outlined">
                      play_arrow
                    </span>
                    {plp.resolution} {plp.duration}
                  </span>
                  <video id={plp.id} className='px-0 mb-2 mb-md-0 img-fluid w-100' loop muted preload="none" poster={plp.thumbnail_image}
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.load()}>
                    <source src={plp.thumbnail_video} type='video/mp4' />
                  </video>
                </a>
              </Col>
            ))}
          </Row>

        </Container>
      </>
    )
  }
}