import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";

// all css import
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// all pages import
import Home from './pages/home';
import Contact from './pages/contact';
import About from './pages/about';
import Privacy from './pages/privacy_policy';
import Terms from './pages/terms_of_use';
import License from './pages/license';
import Plp from './pages/plp';
import SearchResult from './pages/search_result';
import Layout from './pages/layout';
import Pdp from './pages/pdp';
import Login from './pages/login';
import Forget from './pages/forgot_password';
import Signup from './pages/signup';
import Cart from './pages/cart';
import MyOrder from './pages/myOrder';
import Checkout from './pages/checkout';
import Joinaspremium from './pages/joinaspremium';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="Home" element={<Home />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="About" element={<About />} />
          <Route path="Privacy" element={<Privacy />} />
          <Route path="Terms" element={<Terms />} />
          <Route path="License" element={<License />} />
          <Route path="Search" element={<SearchResult />} />
          <Route path="Plp" element={<Plp />} />
          <Route path="Pdp" element={<Pdp />} />
          <Route path="Login" element={<Login />} />
          <Route path="Forget" element={<Forget />} />
          <Route path="Signup" element={<Signup />} />
          <Route path="Joinaspremium" element={<Joinaspremium />} />
          <Route path="Cart" element={<Cart />} />
          <Route path="MyOrder" element={<MyOrder />} />
          <Route path="Checkout" element={<Checkout />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
