import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useState } from "react";
import logo from '../../src/logo_1.svg';

export default function Signup() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    let handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let res = await fetch("https://indianfootage.com/api/sign_up.php", {
                method: "POST",
                body: JSON.stringify({
                    name: name,
                    email: email,
                    mobileNumber: mobileNumber,
                    password: password,
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setName("");
                setEmail("");
                //alert(JSON.stringify(resJson));
                alert("User created successfully");
                setMessage("User created successfully");
                window.location.replace('/#/Login');
                // location.href="/#/Login";
            } else {
                setMessage("Some error occured");
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <Container fluid className='d-flex justify-content-center p-0'>
                <div className='d-flex w-100 h-100 align-items-center'>
                    <div className='col-md-8 col-xxl-7 d-none d-md-block p-0' style={{
                        backgroundImage: 'url(https://images.pexels.com/photos/13617132/pexels-photo-13617132.jpeg)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: window.innerHeight
                    }}>

                    </div>

                    <main className='col-12 col-md-4 px-md-5 col-xxl-5'>
                        <a href="/">
                            <img src={logo} alt='Indian footages Logo' className='img-fluid mb-3' />
                        </a>
                        <h1 className='font-32'>Sign Up</h1>
                        <p className='text-muted'>We'll never share your email with anyone else.</p>

                        <Row className='mt-2 w-100'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Mobile</Form.Label>
                                    <Form.Control type="tel" placeholder="Enter Mobile" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>
                                <div className="d-grid mt-5">
                                    <Button variant="primary" type="submit" className='btn btn-secondary py-2'>
                                        Sign Up
                                    </Button>
                                </div>

                                <div className="message">{message ? <p>{message}</p> : null}</div>
                            </Form>
                        </Row>
                        <Row className='d-flex justify-content-end mt-3'>
                            <Col className='col-auto me-md-4'>
                                <Button variant="outline-dark" className="d-flex align-items-center border-0" href="/#/Login">
                                    Already have an account?
                                </Button>
                            </Col>
                        </Row>

                    </main>
                </div>
            </Container>
        </>
    )
}