import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap';
var cart = [];
if (localStorage.cart) {

    cart = JSON.parse(localStorage.cart);

}
function addToCart(product_id, qty, name, image, price) {
    alert("Item Added To Cart");
    for (var i in cart) {
        if (cart[i].product_id == product_id) {
            console.log("Already In Cart");
           // cart[i].Qty = parseInt(cart[i].Qty) + parseInt(qty);  // replace existing Qty
            //showCart();
            saveCart();
            //alert("l");
            showCart();
            return;
        }
    }
    var item = { Header: "I", product_id: product_id, Qty: qty, Name: name, Image: image, Price: price };
    cart.push(item);
    saveCart();

}
function saveCart() {
    localStorage.cart = JSON.stringify(cart);
}
function deleteItem(product_id) {

    for (var i in cart) {

        if (cart[i].product_id == product_id) {
            var index = i;  // replace existing Qty
            //alert(index);

        }
    }

    cart.splice(index, 1);
    saveCart();

    showCart();

}
function showCart() {
    cart = JSON.parse(localStorage.cart);

    var outp = JSON.stringify(cart);

}
function IncreaseItem(product_id) {

    for (var i in cart) {

        if (cart[i].product_id == product_id) {
            cart[i].Qty = parseInt(cart[i].Qty) + 1;

        }
    }


    saveCart();

    showCart();

}
function DecreaseItem(product_id) {

    for (var i in cart) {

        if (cart[i].product_id == product_id) {
            if (cart[i].Qty == 1) {
                deleteItem(product_id);

                return;
            }
            cart[i].Qty = parseInt(cart[i].Qty) - 1;

        }
    }


    saveCart();

    showCart();

}
function AddToCart(props) {

    return (
        <div>
            {(() => {
                if (props.btntype == "plp") {
                    return (
                        <Button className="d-flex align-items-center justify-content-center border-0" title="Add to cart" onClick={() => addToCart(props.id, 1, props.name, props.image, props.price)}>
                            <span class="material-symbols-outlined font-16">
                                shopping_cart
                            </span>
                        </Button>
                    )

                }
                else if (props.btntype == "pdp") {
                    return (
                        <Button variant='secondary' className='border-0' onClick={() => addToCart(props.id, 1, props.name, props.image, props.price)}>Add to cart</Button>
                       
                    )

                }

            })()}

        </div>
    );
}

export default AddToCart