import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
export default function Checkout() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {


    setIsLoaded(true);
    var cart = [];
    cart = JSON.parse(localStorage.cart);
    setItems(cart);
  }, [localStorage.Cart])
  var cart = [];
  if (localStorage.cart) {

    cart = JSON.parse(localStorage.cart);

  }
  var len=cart.length;
var total_price=0;
for(let i=0;i<len;i++)
{
  //alert(cart[i].Price);
  total_price=total_price+parseFloat(cart[i].Price);
}
let handleConfirmOrder = async (e) => {
  e.preventDefault();
//alert(localStorage.cart);
  try {
      let res = await fetch("https://indianfootage.com/api/rcvjson_place_order.php?user_id="+localStorage.user_id, {
          method: "POST",
          body: localStorage.cart,
      });
      let resJson = await res.json();
      if (res.status === 200) {
          
         //alert(JSON.stringify(resJson));
        //  setMessage(resJson.Message);
        //alert(resJson.order_string);
          if(resJson.order_status=="1")
          {
            
             alert("Please pay for order id: "+resJson.order_id);
             localStorage.cart="";
             window.location.replace(resJson.payment_url);
             //window.location.replace('/');
             // window.location.replace('/');
          }
          else{
              

          }
      } else {
         // setMessage("Some error occured");
      }
  } catch (err) {
      console.log(err);
  }
};
  if (localStorage.user_id) {
    return (
      <>
        <Container className="mt-5 mb-5">
          <Col className="mb-5">
            <h1 className='font-32 border-bottom pb-2'>Checkout</h1>
          </Col>

          <Row className="row justify-content-between">
            <Col md={7} className="mb-4">
              <div className='d-flex border p-4'>
                <div className="d-block">
                  <p>Name: {localStorage.name}</p>
                  <p>Email: {localStorage.ez}</p>
                  <p>Phone: {localStorage.mz}</p>

                </div>
              </div>
            </Col>

            <Col md={{ span: 4 }} className="mb-5">
              <Col className='border p-3 mb-4'>
                <h2 className="mb-3 font-20">Order Summary</h2>

                {items.map((cart, i) => (
                  <div className="d-flex flex-wrap">
                    <Col lg={5} className='mb-3'><img src={cart.Image} alt="" className='img-fluid' /></Col>
                    <Col lg={7} className='px-lg-3'>
                      <p className="mb-1"><span className="bold">Clip ID:</span> {cart.Name}</p>
                      <p className="mb-1"><span className="bold">Qty:</span> {cart.Qty}</p>
                      <p className="mb-1 d-flex align-items-center">
                        <span className="bold">Price: </span>
                        <span className="material-symbols-outlined font-16">
                          currency_rupee
                        </span> {cart.Price}</p>
                    </Col>
                  </div>
                ))}

              </Col>

              <Form>
                <Col className="mb-5">
                  <div className="border p-3">
                    <h3 className="mb-1 font-20">Select Payment Details</h3>
                    <p>Choose a payment method for Total Amount: {total_price}</p>
                    {['radio'].map((type) => (
                      <div key={'default-${type}'} className="mb-3">
                        <Form.Check
                          type={type}
                          id={'upi'}
                          label={'Prepaid'}
                          checked={'checked'}
                        />
                      </div>
                    ))}
                  </div>
                </Col>

                <div className="d-grid">
                  <Button variant="primary" className="btn btn-secondary" onClick={handleConfirmOrder}>Pay Now</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}