import React, { useEffect, useState } from "react";
import { Offcanvas, Nav, Button, Col, Row } from "react-bootstrap";

import Preloader from '../preloader';

export default function OffcanvasMenu() {
    const [show, setShow] = useState(false);
    const [ek, setEk] = useState(false);

    const handleClose = (() => {
        setShow(false)
    });
    const handleSelect = ((selectedKey) => {

        // alert(selectedKey);
        setEk(selectedKey);
    });
    const handleShow = () => setShow(true);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    useEffect(() => {
        fetch("https://indianfootage.com/api/sync_customer.php?tname=footage_category")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.items);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])
    if (error) {
        return (
            <>
                <Row className='d-flex align-items-center px-3'>
                    <Col className='col-auto p-0'>
                        <Button onClick={handleShow} className='border-0 d-flex align-items-center px-1 py-0 btn-menu'>
                            <span className="material-symbols-outlined font-32">
                                menu
                            </span>
                        </Button>
                    </Col>
                    <Col className='col-auto p-0 bold ms-2 d-none d-lg-block'>Browse Category</Col>
                </Row>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Browse Category</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav defaultActiveKey="/" className="flex-column">
                            <Nav.Link href="/">Home</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
    else if (!isLoaded) {
        return <Preloader />;
    } else {
        return (
            <>
                <Row className='d-flex align-items-center px-3'>
                    <Col className='col-auto p-0'>
                        <Button onClick={handleShow} className='border-0 d-flex align-items-center px-1 py-0 btn-menu'>
                            <span className="material-symbols-outlined font-32">
                                menu
                            </span>
                        </Button>
                    </Col>
                    <Col className='col-auto p-0 bold ms-2 d-none d-md-block'>Browse Category</Col>
                </Row>


                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Browse Category</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav activeKey={ek} className="flex-column" onSelect={handleSelect}>
                            <Nav.Link eventKey={100} href="/">Home</Nav.Link>
                            {items.map((category, i) => (
                                <Nav.Link href={"/#/plp?cat=" + category.id} onClick={handleClose} eventKey={category.id} >{category.category}</Nav.Link>
                            ))}
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
}