import React, { useState, useEffect } from "react";
import { Navbar, Nav, Dropdown, DropdownButton } from 'react-bootstrap';
function logout() {
    //  alert();
    localStorage.setItem('user_id', "");
    localStorage.setItem('name', "");
    localStorage.setItem('ez', "");
    localStorage.setItem('mz', "");
    window.location.replace('/');

}





export default function CartNav() {
    const [count, setCount] = useState(0);
   
    useEffect(() => {

        
    },[count]);
    setInterval(function () {var cart = [];
        if (localStorage.cart) {

            cart = JSON.parse(localStorage.cart);

        }
        var len = cart.length;
       // alert(len);
        setCount((count) => len);
    }, 1000);
    //alert(localStorage.user_id);



    if (localStorage.user_id) {
        var nm = localStorage.name;
        let myArray = "";
        let fn = "";
        if (nm) {
            myArray = nm.split(" ");
            let firstChar = myArray[0].charAt(0);
            fn = myArray[0];
            let secondChar = myArray[1].charAt(0);
        }
        else {
            fn = "No Name";
            let firstChar = "";
            let secondChar = "";
        }

        return (
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto align-items-center">

                    <Nav.Link variant="nav-link" className='font-14 btn-prime px-4 me-3 bold' href="/#/Joinaspremium">Join as Premium</Nav.Link>
                    
                    <DropdownButton align={{ lg: 'end' }} title={fn} id="dropdown-menu-align-responsive-2" className="profile me-3">
                        <Dropdown.Item eventKey="1">My Profile</Dropdown.Item>
                        <Dropdown.Item eventKey="2" href="/#/myOrder">My Order</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={logout}>Logout</Dropdown.Item>
                    </DropdownButton>

                    <Nav.Link variant="nav-link" className="btn-cart mb-0 position-relative" href="/#/Cart">
                        <span className="has-items">{count}+</span>
                        <span className="material-symbols-outlined font-32">
                            shopping_cart
                        </span>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse >
        );
    }
    else {
        return (
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto align-items-center">
                    <Nav.Link variant="nav-link" className='font-14 btn-prime px-4 me-1 bold' href="/#/Login">Join as Premium</Nav.Link>
                    <Nav.Link variant="nav-link" className='font-14 mx-md-1 btn-login' href="/#/Login">Login/Signup</Nav.Link>
                    <Nav.Link variant="nav-link" className="btn-cart mb-0 position-relative" href="/#/Cart">
                        <span className="has-items">{count}+</span>
                        <span className="material-symbols-outlined font-32">
                            shopping_cart
                        </span>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse >
        );

    }

}