import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Preloader from '../components/preloader';
import SearchContainer from '../components/search_container';

export default function SearchResult() {

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);


  useEffect(() => {

    fetch("https://indianfootage.com/api/search_tname.php?tname=footage_product&search=" + search)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.items);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )


  }, [search])
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  else if (!isLoaded) {
    return <Preloader />;
  }
  else {
    return (
      <>
        <Helmet>
          <title>Search - Indianfootage</title>
        </Helmet>
        <SearchContainer />
        <Container>
          <Row>
            <Breadcrumb className="mb-3">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Search Result {search}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>

          <Row className="mb-3">
            {items.map((plp, i) => (
              <Col className='col-4 ps-0 pe-1 position-relative plp_img'>
                <a href={"/#/pdp?id=" + plp.id + "&cat=" + plp.category} className="position-relative">
                  <span className="d-flex align-items-center title position-absolute">
                    <span class="material-symbols-outlined">
                      play_arrow
                    </span>
                    {plp.resolution} {plp.duration}
                  </span>
                  <video id={plp.id} className='px-0 mb-2 mb-md-0 img-fluid w-100' loop muted preload="none" poster={plp.thumbnail_image}
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.load()}>
                    <source src={plp.thumbnail_video} type='video/mp4' />
                  </video>

                </a>
              </Col>
            ))}

          </Row>
        </Container>
      </>
    )
  }
}
